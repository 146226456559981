<template>
    <div v-if="level" class="slider">
      <input  type="range" min="min" max="max" step="step" v-model="level"/> 
      <ul class="text-lines">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
</template>

<script>

export default {
  name: 'slider',
    data() {
          return {
              showlogs:false,
              level:false
          }
     },
     props:{
         min:Number,
         max:Number,
         step:Number,
         value:Number
     },
     emits:['updateLevel'],
     watch: { 
        value:{
             handler: function(val, oldVal) {
                this.level = this.value
            },
            deep: true
        },
        level: {
            handler: function(val, oldVal) {
                 this.$emit('updateLevel', this.level)
            },
            deep: true
        }
     },
     beforeMount(){
         this.level = this.value
     }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


    // track ---
    $trackWidth: 100%;
    $trackHeight: 30px;
    $trackBorder: none; //1px solid #000000;
    $trackColor: transparent;
    $trackBorderRadius: 2px;
    $lines:#b5b6b9;

    // thumb
    $thumbWidth: 15px;
    $thumbHeight: 35px;

    $thumbBorder: 2px solid #d3d4d5;
    $buttonColor: #ffffff;

    $thumbBorder-hover:2px solid #d3d4d5;
    $buttonColor-hover:#ffffff;

    $thumbBorderRadius: 5px;
    $buttonMargintop: -9px;

.slider{
    margin-top:15px;
    position: relative;
    display:block;
    height:30px;
    // variables --------------------------------------------------------

    ul.text-lines{
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        padding:0;
        margin:0;
        list-style: none;

        -moz-transform: scale(-1, -1);
        -o-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
        transform: scale(-1, -1);

        z-index:0;

        li{
            height: 5px;
            border-bottom: 1px solid $lines;
        
            &:nth-child(5){
                width:100%;
            }
            &:nth-child(4){
                width:80%;
            }
            &:nth-child(3){
                width:70%;
            }
            &:nth-child(2){
                width:50%;
            }
            &:nth-child(1){
                width:20%;
            }
        }
    }


    input[type=range] {
        position:absolute;
        top:0;
        left:0;
        z-index:1;
        background: $trackColor;
        appearance: none;
        -webkit-appearance: none;
         box-sizing: border-box;
         width: $trackWidth;
    }

    input[type=range]:focus {
         outline: none;
    }

    // webkit ------------------------------------------------------------

    // track --
    input[type=range]::-webkit-slider-runnable-track {
        width: $trackWidth;
        height: $trackHeight;
        cursor: pointer;
        background: $trackColor;
        border-radius: $trackBorderRadius;
        border: $trackBorder;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
         background: $trackColor;
    }

    // thumb --
    input[type=range]::-webkit-slider-thumb {

        cursor: pointer;
        -webkit-appearance: none;
        border: $thumbBorder;
        height: $thumbHeight;
        width: $thumbWidth;
        border-radius: $thumbBorderRadius;
        background: $buttonColor;
        margin-top: $buttonMargintop;

        &:hover{
            border: $thumbBorder-hover;
            background: $buttonColor-hover;
        }
        
    }

 
    // mozilla ------------------------------------------------------------

    // track ---
    input[type=range]::-moz-range-track {
        width: $trackWidth;
        height: $trackHeight;
        background: $trackColor;
        border-radius: $trackBorderRadius;
        border: $trackBorder;
    }

    // thumb ---
    input[type=range]::-moz-range-thumb {
        cursor: pointer;
        border: $thumbBorder;
        height: $thumbHeight;
        width: $thumbWidth;
        border-radius: $thumbBorderRadius;
        background: $buttonColor;

        &:hover{
            border: $thumbBorder-hover;
            background: $buttonColor-hover;
        } 
    }


    // microsoft ------------------------------------------------------------

    // track ---
    input[type=range]::-ms-track {
        width: $trackWidth;
        height: $trackHeight;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type=range]::-ms-fill-lower {
        background: $trackColor;
        border: $trackBorder;
        border-radius: $trackBorderRadius;
    }

    input[type=range]::-ms-fill-upper {
        background: $trackColor;
        border: $trackBorder;
        border-radius: $trackBorderRadius;
    }

    // thumb ---
    input[type=range]::-ms-thumb {
        cursor: pointer;
        border: $thumbBorder;
        height: $thumbHeight;
        width: $thumbWidth;
        border-radius: $thumbBorderRadius;
        background: $buttonColor;

        &:hover{
            border: $thumbBorder-hover;
            background: $buttonColor-hover;
        }
    }

    input[type=range]:focus::-ms-fill-lower {
        background: $trackColor;
    }

    input[type=range]:focus::-ms-fill-upper {
        background: $trackColor;
    }

}


</style>
