<template>

  <div>
    <resume ref="resume" />
  </div>

</template>

<script>


// @ is an alias to /src
import resume from '@/components/resume.vue'

export default {
  name: 'Home',
  methods:{
    savePDF(){
         console.log(this.$refs)
        // this.$refs.resume.savePDF()
      }
  },
  components: {
    resume
  }
}
</script>
