<template>

     
      <ul class="silder-btn" v-if="document.buttons.useBtn">
        <li v-for="btn in document.buttons.btn" :key="btn.level" 
            @click="updateLevel(btn.level)" 
            class="p-2 border flex-grow cursor-pointer select-none"> 
            {{btn.label}}
          </li>
      </ul>
 
      <Slider 
              @updateLevel="updateLevel"
              :min="0" 
              :max="100" 
              :step="33" 
              :value="level" />

      <ul class="info">
          <li v-for="section, index in document.sections" 
            :key="index">
      
            <h2>{{section.label}}</h2>
              <Transition v-for="content in section.content" 
                          :key="content.level">

                <span v-if="level >= content.level">
                  {{content.text}}
                </span>

              </Transition>
          </li>
      </ul>

</template>

<script>

  import Slider from '@/components/slider.vue'
  import doc from '@/assets/doc.json'

  export default {
    name: 'resume',
      data() {
            return {
                showlogs:false,
                level:1,
                document:doc
            }
        },
        components:{
          Slider
        },
        methods:{
          animateLevel(){
            let animate = setInterval( () => {
                if(this.level > 1){
                    this.level--
                }else{
                  clearInterval(animate);
                }
            }, 10)
          },
          updateLevel(val){
            this.level = val
          }
        },
        mounted(){
          //this.animateLevel()
        }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">



   ul{
    margin:0;
    padding:0;
    list-style-type: none;
    }

    ul.info{
      li{
        margin:2rem 0;
        &:last-child{
          padding-bottom:150px;
        }
      }
    }

    h2{
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }

    span{
      font-size:1.2rem;
      &:before{
        content:" ";
      }
    }


    .silder-btn{
      display:flex;
      justify-content: space-between;
      width:100%;
     // border:1px solid red;
      li{
        cursor: pointer;
        margin:0;
        padding:0 0 0 0;
 

      }
    }


    // transition ---------------------------------------------------

      .v-enter-active,
      .v-leave-active {
        transition: opacity .5s ease-in-out;
      }

      .v-enter-from,
      .v-leave-to {
        opacity: 0;
      }

</style>
