<template>

    <div :class="{'minimize': scrollup}">

      <header>

        <div class="container">

          <!-- <router-link to="/"> -->
           <h1>Rick Truhls</h1>
          <!-- </router-link> -->
          <div> Creative / Technology / Prototypes / Products </div>

          <div>  
              <a class="email" href="mailto:&#114;&#105;&#099;&#107;&#064;&#114;&#105;&#099;&#107;&#116;&#114;&#117;&#104;&#108;&#115;&#046;&#099;&#111;&#109;">
              &#114;&#105;&#099;&#107;&#064;&#114;&#105;&#099;&#107;&#116;&#114;&#117;&#104;&#108;&#115;&#046;&#099;&#111;&#109;
              </a>
          </div>

          <div>
             <a href="https://www.linkedin.com/in/rick-truhls/"> LinkedIn/rick-truhls</a>
          </div>



        </div>

      </header>


          <section id="content">
            <div  class="container">
                <router-view  ref="routes"/>
            </div>
          </section>
   

      <footer>

        <div  class="container">
          All rights reserved. Copyright &copy; {{date}}
        </div>

      </footer>

    </div>

</template>

<script>

export default {
  name: 'home',
    data() {
          return {
              showlogs:false,
              date:false,
              logo:false,
              scrollup:false
          }
     },
     methods:{
      trackScrollPosition(){

        if(window.scrollY > 10){
          this.scrollup = true
        }
        else{
          this.scrollup = false
        }
   
      }
     },
     beforeMount(){
          const d = new Date();
          this.date = d.getFullYear();
          window.addEventListener('scroll', this.trackScrollPosition);
     }
}
</script>
 
<style lang="scss">


    //vars ------------------------------
    $text-color: #2c3e50;
    $accent: #1a4d80;
    $bg-color-1: #FFF;
    $bg-color-2:#e0e0e0;

    html,
    body{
      margin:0;
      width:0;
      background-color:$bg-color-1;
      font-family: 'usual', sans-serif;

    }

    *{
      box-sizing: border-box;
    }

    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $text-color;
    }

    header{
      position:fixed;
      display:flex;
      align-items: center;
      justify-content: center;
      top:0;
      left:0;
      width:100%;
      height:350px;
      background-color:$bg-color-2;
      font-size: 1.15rem;
      color: $text-color;
      transition: all .25s ease;
      z-index:100;

      h1{
        font-size: 2.75rem;
        padding:0;
        margin:0;
        line-height: 4rem;
        transition: all .25s ease;
        font-family:'Superior Title';
        text-transform: uppercase;
      }

      a{
        text-decoration: none;
        color: $text-color;


        &:hover{
          text-decoration:underline;
          color:$accent;
        }

      }
    }


    .minimize header{
        height:150px;
        font-size: 1rem;
        h1{
          font-size: 2rem;
          line-height: 3rem;
        }

    }

    section{
      padding-top:375px;
      width:100vw;
      min-height: calc(100vh - 100px);
      transition: height .25s ease;
    }


 
    .container{
      width:65vw;
      max-width:1100px;
      margin:0 auto;
    }


    footer{
      display:flex;
      align-items: center;
      justify-content:flex-end;
      background-color:$bg-color-2;
      width:100vw;
      padding:20px;
      min-height:100px;
      font-size: 1rem;
    }

</style>
 